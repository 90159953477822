import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
    HeaderContainer,
    RelativeWrapperWithImage,
    MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import { platformCompanies, moreCompanies } from 'components/pages/Integrations/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import TabNavigator from 'components/TabNavigator';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';

const Wrapper = styled(WidthWrapper).attrs({ size: 1225 })`
  ${HeaderMedium} {
    padding-top: 100px;
    text-align: center;
    }
  .card {
    margin-top: 10px;
  }
  p {
    margin-top: 10px;
  }
  .terminal{
    margin-left: 20px;
  }
  .top {
    margin-top: 30px;
  }
  .bottom {
    margin-bottom: 30px;
  }
  .blue {
    color: #4098f7;
  }
  .red {
    color: #e00e0e;
  }
  .header-title {
    margin-top: 40px;
    margin-bottom:10px;
  }
  h1.header-title {
    font-size:25px;
    text-transform: uppercase;
  }

   .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

  /*ACCORDION*/

details {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 23px 100px 0 rgb(0 0 0 / 8%);
  padding: 20px;
}

summary {
  position: relative;
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  outline:none;
  list-style-type: none;
  &::-webkit-details-marker { display:none; }
}

.accordion{
  margin-top:20px;
}

.accordion-content{
  padding:15px 20px 10px 0px;
  font-size: 16px;
  color: #7D7987;
  line-height: 30px;
}


/* Content in Accordion fades in */
details[open] > div {
  opacity: 0;
  animation-name: showContent;
  animation-duration: 0.6s;
  animation-delay: 0.16s;
  animation-fill-mode: forwards;
}


/* Arrow for Accordion */
details summary:after {
  content: '\f067';
  margin-top: 0px;
  left: 24px;
  font-size: 16px;
  font-family: 'FontAwesome';
  float: right !important;
}

details[open] summary:after {
  content: '\f00d';
  font-family: 'FontAwesome';
  float: right !important;
}


/* Animation for Focus */
@keyframes showFocus {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 0 0 0 2px #303859;
  }
}

/* Animation for Content */
@keyframes showContent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation for Arrow */
@keyframes flipArrowUp { 100% { -webkit-transform: rotate(-135deg); transform:rotate(-135deg); } }
@keyframes flipArrowDown { 100% { -webkit-transform: rotate(45deg); transform:rotate(45deg); } }


@media screen and (max-width: 640px) {
    // .two-liner summary:after {
    //     margin-top: -20px;
    // }

    summary {
        font-size: 16px;
    }

    h1.header-title {
        font-size: 20px;
    }

    details summary:after, details[open] summary:after {
        display: none;
    }
}
  
`;

const Row = styled.div`
    max-width:1300px;
    // margin-left:auto;
    // margin-right:auto;
    // display: flex;
    // column-gap: 30px;

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;


const Faq = () => {
    const intl = useIntl()

    return (
        <Layout>
            <Helmet>
                {/* <title>Frequently Asked Questions | Shipkoo</title> */}
                <title>{intl.formatMessage({ id: "resources-faq" })}</title>
                <link rel="alternate" href={intl.formatMessage({ id: "faq-link" })} hreflang={intl.formatMessage({ id: "language" })} />
                <link rel="alternate" href={intl.formatMessage({ id: "faq-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
                <link rel="alternate" href={intl.formatMessage({ id: "faq-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
                <link rel="alternate" href={intl.formatMessage({ id: "faq-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
                <meta name="description" content="" />
                <meta property="og:title" content={intl.formatMessage({ id: "resources-faq" })} />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content={intl.formatMessage({ id: "resources-faq" })} />
                <meta name="twitter:description" content="" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            </Helmet>
            <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-faq" })}</Breadcrumbs>
            <RelativeWrapperWithImage>
                <WidthWrapper whiteMobile>
                    <Wrapper>
                        <div>
                            <h1 className="header-title">
                                {intl.formatMessage({ id: "faq-title" })}
                            </h1>
                        </div>
                        <Row>
                            <div className="card">
                                <div className="card-body">
                                    <div className="accordion">
                                        <details>
                                            <summary aria-describedby="Where is your warehouse located in the US?">{intl.formatMessage({ id: "faq-question1" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer1" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="Does Shipkoo be able to collect directly from the supplier factory?">{intl.formatMessage({ id: "faq-question2" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Would the shipment be picked up at the sorting centre?">{intl.formatMessage({ id: "faq-question3" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you have someone who can pick up from my supplier and then ship?">{intl.formatMessage({ id: "faq-question4" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Can you explain to me about your work?">{intl.formatMessage({ id: "faq-question5" })}</summary>
                                            <div className="accordion-content">
                                                <p>{intl.formatMessage({ id: "faq-answer5-one" })}</p>
                                                <p>{intl.formatMessage({ id: "faq-answer5-two" })}</p>
                                                <p>{intl.formatMessage({ id: "faq-answer5-three" })}</p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you have a broker in-house?">{intl.formatMessage({ id: "faq-question6" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="What’s the difference between DDP and DAP(DDU) service?">{intl.formatMessage({ id: "faq-question7" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer7-one" })}
                                                </p>
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer7-two" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Does Shipkoo have any payment terms applied?">{intl.formatMessage({ id: "faq-question8" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer8" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Can we only keep the stock for 30 days?">{intl.formatMessage({ id: "faq-question9" })}</summary>
                                            <div className="accordion-content">
                                                <p>{intl.formatMessage({ id: "faq-answer9" })}</p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you extra charge us if we used our own shipping bags?">{intl.formatMessage({ id: "faq-question10" })}</summary>
                                            <div className="accordion-content">
                                                <p>{intl.formatMessage({ id: "faq-answer10" })}</p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="Where is your warehouse located in the US?">{intl.formatMessage({ id: "faq-question11" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer11" })}
                                                    
                                                </p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="Will it be possible to ship the return’s shipment back to origin country?">{intl.formatMessage({ id: "faq-question12" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer12" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Does your shipping prices include fuel charge?">{intl.formatMessage({ id: "faq-question13" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="Are the border fees include in the shipping price to UK?">{intl.formatMessage({ id: "faq-question14" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you provide API integration?">{intl.formatMessage({ id: "faq-question15" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="How much will you charge per pcs after 30 days?">{intl.formatMessage({ id: "faq-question16" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer16" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Can you ship hydrogel face masks?">{intl.formatMessage({ id: "faq-question17" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes-2" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="Do you offer kitting services to put multiple items together from different suppliers?">{intl.formatMessage({ id: "faq-question18" })}</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    {intl.formatMessage({ id: "faq-answer-yes-2" })}
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you have MOQ ?">{intl.formatMessage({ id: "faq-question19" })}</summary>
                                            <div className="accordion-content">
                                                <p> {intl.formatMessage({ id: "faq-answer19" })}</p>
                                            </div>
                                        </details>
                                        <details className="two-liner">
                                            <summary aria-describedby="How do you deal with fulfilling orders during the holidays?">{intl.formatMessage({ id: "faq-question20" })}</summary>
                                            <div className="accordion-content">
                                                <p> {intl.formatMessage({ id: "faq-answer20" })}</p>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="accordion">
                                        <details>
                                            <summary aria-describedby="Where is your warehouse located in the US?">How is the handling of duty and VAT when imported to EU and non-EU countries?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    Determine by the custom when goods sending.
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Will it be possible to ship the return’s shipment back to origin country?">Will it be possible to ship the return’s shipment back to origin country?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    No. It will follow the shipment return back to sender address.
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Does your shipping prices include fuel charge?">Does your shipping prices include fuel charge?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    Yes.
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Are the border fees include in the shipping price to UK?">Are the border fees include in the shipping price to UK?</summary>
                                            <div className="accordion-content">
                                                <p>Yes.</p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you provide API integration?">Do you provide API integration?</summary>
                                            <div className="accordion-content">
                                                <p>Yes.</p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="How much will you charge per pcs after 30 days?">How much will you charge per pcs after 30 days?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    $0.02USD/unit. *T&amp;C
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Can you ship hydrogel face masks?">Can you ship hydrogel face masks?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    Yes. We do.
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you offer kitting services to put multiple items together from different suppliers?">Do you offer kitting services to put multiple items together from different suppliers?</summary>
                                            <div className="accordion-content">
                                                <p>
                                                    Yes. We do.
                                                </p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="Do you have MOQ ?">Do you have MOQ ?</summary>
                                            <div className="accordion-content">
                                                <p>No.</p>
                                            </div>
                                        </details>
                                        <details>
                                            <summary aria-describedby="How do you deal with fulfilling orders during the holidays?">How do you deal with fulfilling orders during the holidays?</summary>
                                            <div className="accordion-content">
                                                <p>Our fulfilment operation center will be closed for only A DAY.</p>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            </div> */}
                        </Row>
                    </Wrapper>
                </WidthWrapper>
            </RelativeWrapperWithImage>
        </Layout>
    );
};

export default Faq;
